<template>
  <div id="bg-santa">
    <div class="header text-center card bg-green-200 col-12">
      <h1> Letter from {{mysticalbeing}}</h1>
      <p> Customize your child's letter from {{mysticalbeing}}! </p>
    </div>
    <div class="flex flex-column align-items-center mt-2">
      <span class="col-11 sm:col-6 card bg-yellow-100">Warning: Long text inputs run the risk of overlapping with other elements on the document!</span>
      <span class="col-11 sm:col-6 card bg-yellow-100">Users from Facebook mobile app may experience odd behavior. Go to {{appurl}} in your preferred browser to guarantee a magical delivery!</span>
    </div>
    <div class="main-content col-12 mt-3 flex flex-column align-items-center sm:flex-row sm:align-items-start">
      <HolidayList class="col-8 sm:col-3 sm:mt-2" @holiday-select="holidaySwitch"/>
      <div class="col-12 lg:col-6 m-2 grid card opacity-90">
        <div class="col-12 grid">
          <div class="col-12 text-center lg:col-6 lg:text-left">
            <div class="col-12">
              <h5>Font Type</h5>
              <Dropdown v-model="selectedFont" :options="fonts" optionLabel="name" optionValue="value" class="mb-2" />
            </div>
            <div class="col-12">
              <h5>Child(ren)'s Name(s)</h5>
              <InputText type="text" v-model="childName" placeholder="e.g. Hank & Izzy" id="childname"/>
            </div>

            <div class="grid col-12 flex flex-column align-items-center">
              <h5>Message</h5>
              <Textarea v-model="message" :autoResize="true" rows="5" cols="30" />
              <Button label="Populate w/ Stock Message" @click="populateMsg" class="col-4 mt-2"/>
            </div>

            <div class="col-12 grid flex-column md:col-6">
              <h5>Signature</h5>
              <Dropdown v-model="selectedClosing" :options="signatureClosings"  class="mb-2" />
              <InputText type="text" v-model="signature" placeholder="e.g. Santa" id="signature"/>
            </div>       
            
          </div>
          <div class="col-12 text-center lg:col-6 flex flex-column align-items-center">
            <h5>Choose a Background</h5>
            <Image :src="selectedBg.value" alt="bg-image" width="250" preview />
            <Button class="mt-2" icon="pi pi-refresh" label="New Background" @click="nextBg()" />
            
          </div>
        </div>
        <div class="col-12 m-2 text-center">
          <Button label="Preview Note" @click="previewNote"/>
          <Button class="ml-2" label="Generate Note" @click="downloadNote"/>
        </div>
      </div>
    </div>

    <div class="footer grid mt-3 align-items-center justify-content-center text-center card bg-pink-800 col-12">
      <div class="col-12 md:col-3 md:text-left">
        <h5>Font by Vanessa @ <a href="http://bythebutterfly.com">By the Butterfly</a></h5>
        <h5>Background images by:</h5>
        <li>Marissa Abao</li>
        <li>Tanya LeClair</li>
        <li>Taes Bernabe</li>
      </div>
      <div class="col-12 md:col-6">
        <h5>Application built by <a href="https://rivannatechnologies.com">Rivanna Technologies LLC.</a></h5>
      </div>
      <div class="col-12 md:col-3">
       <a href="https://www.buymeacoffee.com/rivannatech" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-white.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref} from 'vue'
import { event, pageview } from 'vue-gtag'
import { jsPDF } from "jspdf"
// import Carousel from 'primevue/carousel';
import xmasbg1 from '../assets/xmasBGs/ChristmasBG-1.png'
import xmasbg2 from '../assets/xmasBGs/ChristmasBG-2.png'
import xmasbg3 from '../assets/xmasBGs/ChristmasBG-3.png'
import xmasbg4 from '../assets/xmasBGs/ChristmasBG-4.png'
import { useToast } from "primevue/usetoast";
import HolidayList from '../components/HolidayList.vue'
export default {
  name: 'Home',
  emits: ['holidaySwitch'],
  setup (_, {emit}) {
    const holiday = ref('Christmas');
    const mysticalbeing = ref('Santa');
    const appurl = ref('https://santa.rivannatechnologies.com')


    const toast = useToast()
    pageview('/')
    const childName = ref();
    const cNFontSize = ref(42)

    const message = ref('')
    const defaultMessage = 'I heard you have been a very good kid this year! All of my elves have been checking on you and they are quite impressed. Mrs. Claus and I are very happy.\n\nHere at the North Pole we\'ve been hard at work getting all of the presents made for all of the good kids like you. Hope you are being nice to your parents this morning.\n\nI better run! Mrs. Claus is calling my name. Comet and Cupid have been fighting all night. They\'re gettin a talkin\' to when we get back to the shop!'
    const bodyFontSize = ref(24);
    
    const signature = ref('Santa Claus')
    const signatureClosings = ['Merry Christmas', 'Be Good', 'Ho Ho Ho', 'Sincerely']
    const selectedClosing = ref('Merry Christmas')
    const sigFontSize = ref(36)
    const bgs = [
      {
        value: xmasbg1,
        introX: 15,
        introY: 64,
        signatureX: 10,
        signatureY: 250,
        bodyWrap: 175
      },
      {
        value: xmasbg2,
        introX: 15,
        introY: 110,
        signatureX: 100,
        signatureY: 250,
        bodyWrap: 185
      },
      {
        value: xmasbg3,
        introX: 15,
        introY: 90,
        signatureX: 80,
        signatureY: 250,
        bodyWrap: 175
      },
      {
        value: xmasbg4,
        introX: 45,
        introY: 35,
        bodyX: 30,
        signatureX: 25,
        signatureY: 225,
        bodyWrap: 160
      },
    ]
    const bgIt = ref(0)

    
    const selectedBg = ref(bgs[bgIt.value])
    const nextBg = () => {
      if(bgIt.value == bgs.length-1){
        bgIt.value = 0
      }else{
        bgIt.value = bgIt.value+1
      }
      selectedBg.value = bgs[bgIt.value]
    }


    
    const fonts = [
      {
        name: 'Fun Font',
        value: 'christmaseve'
      },
      {
        name: 'Handwritten',
        value: 'Caveat-VariableFont_wght'
      }
    ]
    const selectedFont = ref('christmaseve')
    const doc = ref(new jsPDF())
    const buildDoc = () => {
      doc.value.addImage(selectedBg.value.value, 'PNG', 0, 0, 210, 297, '','FAST')

      // INTRO
      doc.value.setFont(selectedFont.value,'normal')
      doc.value.setFontSize(cNFontSize.value)
      doc.value.text(`Dear ${childName.value || 'Kids'},`, selectedBg.value.introX, selectedBg.value.introY)


      // BODY
      doc.value.setFontSize(bodyFontSize.value)  
      if (message.value == '') message.value = defaultMessage   
      var strArr = doc.value.splitTextToSize(message.value, selectedBg.value.bodyWrap)
      doc.value.text(strArr, (selectedBg.value.bodyX || selectedBg.value.introX+5), (selectedBg.value.bodyY || selectedBg.value.introY+18))


      // SIGNATURE
      doc.value.setFontSize(sigFontSize.value)
      doc.value.text(`${selectedClosing.value}!!`, selectedBg.value.signatureX, selectedBg.value.signatureY)
      doc.value.text(`${signature.value || mysticalbeing.value}`, selectedBg.value.signatureX+20, selectedBg.value.signatureY+12)
      
    }
    
    const previewNote = () => {
      buildDoc();
      var string = doc.value.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();
    }
    const downloadNote = () => {
      event('LetterGenerated', { lettergenerated: 1 })
      buildDoc();
      doc.value.save(`MerryChristmas-${Date.now().toString().slice(-4)}.pdf`)
      toast.add({severity:'success', summary: 'Download Successful!', detail:'🎄Merry Christmas from the Team at Rivanna Technologies!🎄', life: 5000});
    }
    const populateMsg = () => {
      message.value = defaultMessage
    }

    const holidaySwitch = (h) => {
      emit('holidayChange',h)
    } 
    return {
      populateMsg,
      bgs,
      selectedBg,
      signature,
      childName,
      message,
      signatureClosings,
      selectedClosing,
      buildDoc,
      downloadNote,
      previewNote,
      fonts,
      selectedFont,
      nextBg,
      holiday,
      mysticalbeing,
      appurl,
      holidaySwitch
    }
  },
  components: {
    HolidayList
  }
}
</script>

<style>
#bg-santa{
  background: url('../../public/snow.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
