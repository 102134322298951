import { createRouter, createWebHistory } from 'vue-router'
import Easter from '../views/Easter.vue'
import Home from '../Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/easter',
    name: 'Easter',
    component: Easter
  },
  {
    path: '/cookies',
    name: 'Cookie Policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../CookiePolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
