<template>
  <div id="bg-easter">
    <div class="header col-12 text-center card bg-blue-200">
      <h1> Letter from {{mysticalbeing}}</h1>
      <p> Customize your child's letter from {{mysticalbeing}}! </p>
    </div>
    <div class="flex flex-column align-items-center mt-2">
      <span class="col-11 sm:col-6 card bg-yellow-100">Warning: Long text inputs run the risk of overlapping with other elements on the document!</span>
      <span class="col-11 sm:col-6 card bg-yellow-100">Users from Facebook mobile app may experience odd behavior. Go to {{appurl}} in your preferred browser to guarantee a magical delivery!</span>
    </div>
    <div class="main-content col-12 mt-3 flex flex-column align-items-center sm:flex-row sm:align-items-start">
       <HolidayList class="col-8 sm:col-3 sm:mt-2" @holiday-select="holidaySwitch"/>
      <div class="col-12 lg:col-6 sm:m-2 grid card opacity-90">
        <div class="col-12 grid">
          <div class="col-12 text-center lg:col-6 lg:text-left">
            <div class="col-12">
              <h5>Font Type</h5>
              <Dropdown v-model="selectedFont" :options="fonts" optionLabel="name" optionValue="value" class="mb-2" />
            </div>
            <div class="col-12">
              <h5>Child(ren)'s Name(s)</h5>
              <InputText type="text" v-model="childName" placeholder="e.g. Hank & Izzy" id="childname"/>
            </div>

            <div class="col-12 flex flex-column ">
              <h5>Message</h5>
              <Textarea v-model="message" :autoResize="true" rows="5" cols="30" />
              <div class="grid flex flex-column sm:flex-row sm:ml-1 mt-2 align-items-center">
                <Button label="Christian Message" @click="populateStockMsg(1)" class="col-4 sm:mr-2 "/>
                <Button label="Other Message " @click="populateStockMsg(0)" class="col-4 mt-2 sm:mt-0"/>
              </div>
              
            </div>

            <div class="col-12 grid flex-column md:col-6">
              <h5>Signature</h5>
              <Dropdown v-model="selectedClosing" :options="signatureClosings"  class="mb-2" />
              <InputText type="text" v-model="signature" placeholder="e.g. Easter Bunny" id="signature"/>
            </div>       
            
          </div>
          <div class="col-12 text-center lg:col-6 flex flex-column align-items-center">
            <h5>Choose a Background</h5>
            <Image :src="selectedBg.value" alt="bg-image" width="250" preview />
            <Button class="mt-2 col-12 sm:col-4" icon="pi pi-refresh" label="New Background" @click="nextBg()" />
            
          </div>
        </div>
        <div class="col-12 sm:m-2 text-center">
          <Button label="Preview Note" @click="previewNote"/>
          <Button class="ml-2" label="Generate Note" @click="downloadNote"/>
        </div>
      </div>
    </div>

    <div class="footer col-12 grid mt-3 align-items-center justify-content-center text-center card bg-indigo-100">
      <div class="col-12 md:col-3 md:text-left">
        <h5>Font by <a href="aencreativestudio.com">aencreative</a></h5>
        <h5>Background images by:</h5>
        <li><a href="https://www.canva.com/p/mahima-bishts-team/">Mahima Bisht's Team</a></li>
        <li><a href="https://www.canva.com/p/luckpicture/">Luck Picture</a></li>
        <li><a href="https://www.canva.com/p/nacmew/">nacmew</a></li>
      </div>
      <div class="col-12 md:col-6">
        <h5>Application built by <a href="https://rivannatechnologies.com">Rivanna Technologies LLC.</a></h5>
      </div>
      <div class="col-12 md:col-3">
       <a href="https://www.buymeacoffee.com/rivannatech" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-white.png" alt="Buy Me A Coffee" style="height: 60px !important;width: 217px !important;" ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref} from 'vue'
import { event, pageview } from 'vue-gtag'
import { jsPDF } from "jspdf"
// import Carousel from 'primevue/carousel';
import ebg1 from '../assets/easterBGs/EasterBG-1.png';
import ebg2 from '../assets/easterBGs/EasterBG-2.png'
import ebg3 from '../assets/easterBGs/EasterBG-3.png'
import ebg4 from '../assets/easterBGs/EasterBG-4.png'
import { useToast } from "primevue/usetoast";
import HolidayList from '../components/HolidayList'
export default {
  name: 'Home',
  emits: ['holidayChange'],  
  setup (_, { emit }) {
    const holiday = ref('Easter');
    const mysticalbeing = ref('The Easter Bunny');
    const appurl = ref('https://easter.rivannatechnologies.com')


    const toast = useToast()
    pageview('/')
    const childName = ref();
    const cNFontSize = ref(32)

    const message = ref('')
    // const defaultMessage = 'I heard you have been a very good kid this year! All of my elves have been checking on you and they are quite impressed. Mrs. Claus and I are very happy.\n\nHere at the North Pole we\'ve been hard at work getting all of the presents made for all of the good kids like you. Hope you are being nice to your parents this morning.\n\nI better run! Mrs. Claus is calling my name. Comet and Cupid have been fighting all night. They\'re gettin a talkin\' to when we get back to the shop!'
    const defaultMessages = [
        "Spring is in the air, and Easter is here! I was so excited to hop over to your home and leave you some treats. This year, I have hidden some colorful eggs around your yard for you to find. I hope you have fun searching for them!\n\nRemember, Easter is not just about the treats and goodies. It's also a time to spend with your family and loved ones. So, don't forget to give your parents and siblings a big hug and tell them how much you love them.\n\nBe good and I'll see you again next year!",
        "As Easter approaches, I wanted to remind you of the true meaning of this holiday. Easter is a time to celebrate the resurrection of Jesus Christ, our Lord and Savior.\n\nJesus died on the cross for our sins, but He rose again on the third day, giving us hope and eternal life. That is why we celebrate Easter with joy and gratitude.\n\nRemember to thank God for all His blessings and to pray for those who are less fortunate. Show kindness and love to everyone you meet, just as Jesus did."
    ]
    const bodyFontSize = ref(20);
   
    const signature = ref('The Easter Bunny')
    const signatureClosings = ['Happy Easter', 'God Bless', 'Sincerely', 'Warm Wishes']
    const selectedClosing = ref('Happy Easter')
    const sigFontSize = ref(28)
    const bgs = [
      {
        value: ebg1,
        introX: 15,
        introY: 25,
        signatureX: 20,
        signatureY: 250,
        bodyWrap: 185
      },
      {
        value: ebg2,
        introX: 15,
        introY: 30,
        signatureX: 20,
        signatureY: 250,
        bodyWrap: 185
      },
      {
        value: ebg3,
        introX: 15,
        introY: 25,
        signatureX: 20,
        signatureY: 250,
        bodyWrap: 185
      },
      {
        value: ebg4,
        introX: 15,
        introY: 25,
        signatureX: 20,
        signatureY: 250,
        bodyWrap: 185
      },
    ]
    const bgIt = ref(0)

    
    const selectedBg = ref(bgs[bgIt.value])
    const nextBg = () => {
      if(bgIt.value == bgs.length-1){
        bgIt.value = 0
      }else{
        bgIt.value = bgIt.value+1
      }
      selectedBg.value = bgs[bgIt.value]
    }


    
    const fonts = [
      {
        name: 'Fun Font',
        value: 'EasterSweet'
      },
      {
        name: 'Handwritten',
        value: 'Caveat-VariableFont_wght'
      }
    ]
    const selectedFont = ref('EasterSweet')
    const lHf = ref(1.75)
    const doc = ref(new jsPDF())
    const buildDoc = () => {
      doc.value.addImage(selectedBg.value.value, 'PNG', 0, 0, 210, 297, '','FAST')

      // INTRO
      doc.value.setFont(selectedFont.value,'normal')
      
      if (selectedFont.value != 'EasterSweet') {
        bodyFontSize.value = 28;
        message.value.replace(/\r?\n/g, "");
        lHf.value = 1.5
      }
      doc.value.setFontSize(cNFontSize.value)
      doc.value.text(`Dear ${childName.value || 'Kids'},`, selectedBg.value.introX, selectedBg.value.introY)


      // BODY
      doc.value.setFontSize(bodyFontSize.value)  
      if (message.value == '') message.value = defaultMessages[0]   
      var strArr = doc.value.splitTextToSize(message.value, selectedBg.value.bodyWrap)
      doc.value.text(strArr, (selectedBg.value.bodyX || selectedBg.value.introX+5), (selectedBg.value.bodyY || selectedBg.value.introY+18),{
        lineHeightFactor: lHf.value
      })


      // SIGNATURE
      doc.value.setFontSize(sigFontSize.value)
      doc.value.text(`${selectedClosing.value}!!`, selectedBg.value.signatureX, selectedBg.value.signatureY)
      doc.value.text(`${signature.value || mysticalbeing.value}`, selectedBg.value.signatureX+20, selectedBg.value.signatureY+20)
      
    }
    
    const previewNote = () => {
      buildDoc();
      var string = doc.value.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();
    }
    const downloadNote = () => {
      event('LetterGenerated', { lettergenerated: 1 })
      buildDoc();
      doc.value.save(`HappyEaster-${Date.now().toString().slice(-4)}.pdf`)
      toast.add({severity:'success', summary: 'Download Successful!', detail:'🐣 Happy Easter from the Team at Rivanna Technologies!', life: 5000});
    }
    const populateStockMsg = (idx) => {
      message.value = defaultMessages[idx]
    }
    const holidaySwitch = (h) => {
      emit('holidayChange',h)
    } 
    return {
      populateStockMsg,
      bgs,
      selectedBg,
      signature,
      childName,
      message,
      signatureClosings,
      selectedClosing,
      buildDoc,
      downloadNote,
      previewNote,
      fonts,
      selectedFont,
      nextBg,
      holiday,
      mysticalbeing,
      appurl,
      holidaySwitch
    }
  },
  components: {
    HolidayList
  }
}
</script>

<style>
#bg-easter{
  background: url('../../public/easter.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
