<template>
    <div class="card sm:ml-2 p-3 flex flex-column align-items-center">
        <h5>Current Holidays</h5>
        <div class="flex flex-row sm:flex-column">
            <Button label="Easter" class="bg-indigo-300 sm:mb-2 mr-2 sm:mr-0" @click="hS('easter')"/>
            <Button label="Christmas" class="bg-green-300" @click="hS('christmas')"/>
        </div>
    </div>
</template>

<script>

    export default {
        emits: ['holidaySelect'],
        setup (_, {emit}) {
            const hS = (h) => {
                emit('holidaySelect',h)
            }
            return {
                hS
            }
        }
    }
</script>