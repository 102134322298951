<template>
   <div class="container">
   <Toast></Toast>
   <div v-if="showBanner" class="sticky top-0 card z-5 confirmation-content max-w-full  justify-content-center align-items-center text-center md:text-left grid">

        <img src="/cookie.png" width="50" height="50" />
        <span class="col-6 w-auto"> We love 🍪s! For more details check our policy
          <router-link to="/cookies">here.</router-link>
        </span>

          <Button label="Opt-In" icon="pi pi-check" @click="okBannerClicked" class="p-button" autofocus />
        
    </div>
    <router-view></router-view>
    
        
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue';
import useCookies from './useCookies';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
export default defineComponent({
  name: 'App',
  setup() {
    const gtag = inject('gtag'); 
    const { showBanner, okClicked } = useCookies(gtag);
    const okBannerClicked = () => okClicked();

    
    return {
      showBanner,
      okBannerClicked,
    };
  },
  components: {
    Button,
    Toast
  }
});
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
}
.slide-enter,
.slide-leave-to {
}
</style>