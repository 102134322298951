<template>
    <div v-if="holiday == 'christmas'">
      <Santa @holiday-change="changeHoliday"/>
    </div>
    <div v-else-if="holiday == 'easter'">
      <Easter @holiday-change="changeHoliday"/>
    </div>
</template>

<script>
import Santa from './views/Santa.vue';
import Easter from './views/Easter.vue';
import { ref } from 'vue'
export default {
  name: 'Home',
  setup () {   
    

    function isInHolidayWindow(holidayDate, daysBefore, daysAfter) {
      const currentDate = new Date();

      const windowStart = new Date(holidayDate);
      windowStart.setDate(windowStart.getDate() - daysBefore);

      const windowEnd = new Date(holidayDate);
      windowEnd.setDate(windowEnd.getDate() + daysAfter);

      return currentDate >= windowStart && currentDate <= windowEnd;
      
    }

    const td = new Date();
    const currYear = td.getFullYear()   


    // const christmasDate = new Date(`December 25, ${currYear}`);
    const easterDate = new Date(`April 1, ${currYear}`);
    const holiday = ref('christmas')
    if ( isInHolidayWindow(easterDate, 15, 30) ){
      holiday.value = 'easter';
    }

    const changeHoliday = (h) => {
      console.log(h)
      if (holiday.value !== h ){
        holiday.value = h
      }
    }
    return {
      holiday,
      changeHoliday
    }
  },
  components: {
    Santa,
    Easter
  }
}
</script>

<style>
</style>
